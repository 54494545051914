<template>
  <div class="page-forum">
    <div class="left">
      <List />
    </div>
    <div class="right">
      <Item />
    </div>
  </div>
</template>

<script>
import List from './list/index.vue'
import Item from './item/index.vue'

export default {
  components: {
    List,
    Item
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.page-forum {
  display: flex;
  .left {
    flex: 1;
    margin-right: 20px;
  }
  .right {
    width: 340px;
  }
}
</style>
