var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forum-wrap"},[_c('div',{staticClass:"forum-header"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading && _vm.page === 1),expression:"loading && page === 1"}]},[(_vm.list.length)?_c('div',[_c('ul',{staticClass:"forum-list"},_vm._l((_vm.list),function(item,index){return _c('li',{key:item.id,staticClass:"forum-list-item"},[_c('div',[_c('img',{staticClass:"forum-list-user",attrs:{"src":item.user_info.headimgurl}}),(item.user_info.is_vip)?_c('div',{staticClass:"isVip"},[_c('img',{attrs:{"src":require('@/assets/vip-icon.png'),"alt":""}})]):_vm._e()]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"userName"},[_vm._v(_vm._s(item.user_info.name))]),_c('div',{staticClass:"headerTitle"},[_vm._v(_vm._s(item.user_info.user_goal))]),_vm._l((_vm.getTag(item.cate_list)),function(v,i){return _c('el-tag',{key:i,staticStyle:{"margin-left":"16px"},attrs:{"size":"mini","type":v == '考场回忆'
                      ? 'warning'
                      : v == '经验分享'
                      ? 'success'
                      : 'danger'}},[_vm._v(" "+_vm._s(v)+" ")])})],2),_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(item.name)}}),(item.img_list)?_c('div',{staticClass:"imgs"},_vm._l((item.img_list.split(',')),function(img,idx){return _c('Img',{key:idx,attrs:{"width":72,"height":72,"src":img,"alt":""}})}),1):_vm._e()]),_c('div',{staticClass:"bottom"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(item.created_at))]),_c('span',{staticClass:"favour"},[_c('i',{staticClass:"f-icon",class:[item.is_liked ? 'icon-likes' : 'icon-like'],on:{"click":function($event){return _vm.handleLike(item.id, item.is_liked, index)}}}),_vm._v(" "+_vm._s(item.like_cnt))]),_c('span',{staticClass:"reply",on:{"click":function($event){return _vm.reply(item.id)}}},[_vm._v(_vm._s(_vm.i18n.Forum.reply))]),(item.user_id == _vm.userInfo.id)?_c('span',{staticClass:"f-icon icon-del del-btn",on:{"click":function($event){return _vm.delReply(item.id)}}}):_vm._e()]),_c('ul',{staticClass:"forum-list"},_vm._l((item.showMore
                    ? item.reply_list
                    : item.reply_list.slice(0, 1)),function(val,idx){return _c('li',{key:val.id,staticClass:"forum-list-item"},[_c('div',[_c('img',{staticClass:"forum-list-user",attrs:{"src":val.user_info.headimgurl}}),(val.user_info.is_vip)?_c('div',{staticClass:"isVip"},[_c('img',{attrs:{"src":require('@/assets/vip-icon.png'),"alt":""}})]):_vm._e()]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"userName"},[_vm._v(_vm._s(val.user_info.name))]),_c('div',{staticClass:"headerTitle"},[_vm._v(" "+_vm._s(val.user_info.user_goal)+" ")]),_vm._l((_vm.getTag(val.cate_list)),function(v,i){return _c('el-tag',{key:i,staticStyle:{"margin-left":"16px"},attrs:{"size":"mini","type":v == '考场回忆'
                            ? 'warning'
                            : v == '经验分享'
                            ? 'success'
                            : 'danger'}},[_vm._v(" "+_vm._s(v)+" ")])})],2),_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(val.name)}}),(val.img_list)?_c('div',{staticClass:"imgs"},_vm._l((val.img_list.split(',')),function(img,idx){return _c('Img',{key:idx,attrs:{"width":72,"height":72,"src":img,"alt":""}})}),1):_vm._e()]),_c('div',{staticClass:"bottom"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(val.created_at))]),_c('span',{staticClass:"favour"},[_c('i',{staticClass:"f-icon",class:[val.is_liked ? 'icon-likes' : 'icon-like'],on:{"click":function($event){return _vm.handleLike(val.id, val.is_liked, idx, index)}}}),_vm._v(" "+_vm._s(val.like_cnt))]),_c('span',{staticClass:"reply",on:{"click":function($event){return _vm.reply(val.id)}}},[_vm._v(_vm._s(_vm.i18n.Forum.reply))]),(val.user_id == _vm.userInfo.id)?_c('span',{staticClass:"f-icon icon-del del-btn",on:{"click":function($event){return _vm.delReply(val.id)}}}):_vm._e()])])])}),0),(item.reply_list.length > 1 && !item.showMore)?_c('div',{staticClass:"reply-total"},[_vm._v(" "+_vm._s(_vm.i18n.Forum.common)+_vm._s(item.reply_list.length)+_vm._s(_vm.i18n.Forum.reply)+"，"),_c('el-button',{staticClass:"reply-view",attrs:{"type":"text"},on:{"click":function($event){return _vm.showMoreComments(index)}}},[_vm._v(_vm._s(_vm.i18n.Forum.Click))])],1):_vm._e()])])}),0),_c('More',{attrs:{"page":_vm.page,"pageSize":_vm.pageSize,"loading":_vm.loading,"totalPage":Math.ceil(_vm.total / _vm.pageSize)},on:{"load":_vm.load}})],1):_vm._e()])]),_c('Reply',{ref:"reply",attrs:{"tabList":_vm.tabList,"getData":_vm.getData}}),(!_vm.list.length && !_vm.loading)?_c('Empty',{attrs:{"isTabs":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }