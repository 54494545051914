<template>
  <el-row class="add" v-loading="loading">
    <!-- <el-col :span="24">
      <el-input
        class="addInpt"
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 6 }"
        contenteditable="true"
        :placeholder="
          forumInfo.user_info
            ? '@ ' + forumInfo.user_info.name
            : '和大家一起分享'
        "
        v-model="textarea"
      >
      </el-input>
    </el-col> -->
    <el-col :span="24">
      <div
        v-html="innerText"
        ref="edit"
        class="edit"
        contenteditable="true"
        @input="handleInput()"
      ></div>
    </el-col>
    <div class="addBottom">
      <div class="addBox">
        <el-popover
          placement="bottom-start"
          popper-class="forum-popver-popover"
          width="312"
          trigger="hover"
        >
          <div>
            {{i18n.Forum.PictureUpload}}
            <span style="color: #bfbfbf">（{{ imgList.length }}/9）</span>
          </div>
          <el-upload
            ref="upload"
            :http-request="uploadRequest"
            list-type="picture-card"
            multiple
            :limit="9"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span style="width: 80px" class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>

          <img
            slot="reference"
            class="bottomImg"
            src="../../../assets/updateImg.png"
            width="22px"
            height="22px"
          />
        </el-popover>
        <el-tooltip placement="top">
          <div class="bottomImg2"></div>
          <div slot="content" style="text-align: center">
            {{i18n.Forum.Enter}}<br />'#'+{{i18n.Forum.Enter2}}<br />{{i18n.Forum.Enter3}}
          </div>
        </el-tooltip>
        <el-radio-group
          class="bottomImg"
          size="mini"
          v-model="checkCate"
          v-if="type === 'add'"
        >
          <el-radio
            border
            :label="item.name"
            v-for="(item, index) in tagList"
            :key="index"
          ></el-radio>
        </el-radio-group>
      </div>
      <el-button class="btn" type="primary" :disabled="disabled" @click="submit"
        >{{i18n.Forum.release}}</el-button
      >
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-row>
</template>

<script>
import { debounce } from 'lodash'
import * as upload from '@/utils/upload'
import api from '@/api/forum'
import { apiGetWebQuestion } from '@/api/api'

const conversionId = async (str) => {
  const markHtml = str.replace(
    /(\<span[^\<\/span\>]+\<\/span\>)/g,
    (spanHtml) => {
      return spanHtml.replace(/(#\d+)/g, '$1-')
    }
  )

  const ids = markHtml.match(/(\s|\&nbsp\;)#(\d+)(\s|\&nbsp\;)/gi) || []
  let practiceMap = {}
  for (let i = 0; i < ids.length; i++) {
    const id = Number(ids[i].replace(/[^\d]+(\d+)[^\d]+/g, '$1'))
    const { data } = await apiGetWebQuestion({ id: id })
    practiceMap[id] = !data || Array.isArray(data) ? null : data
  }

  return markHtml
    .replace(/(\s|\&nbsp\;)#(\d+)(\s|\&nbsp\;)/gi, (all, first, middle) => {
      const data = practiceMap[middle] || { title: '' }
      return ` <a class="practice" contenteditable="false" data-id="${middle}" target="_blank" href="/#/Exercises?clid=${data.cate_info.cate_id}&practiceId=${middle}&from=search">机经题 <b>#${middle} ${data.title}</b> <i class="iconfont icon-close"></i></a>&nbsp;`
    })
    .replace(/(\<span[^\<\/span\>]+\<\/span\>)/g, (spanHtml) => {
      return spanHtml.replace(/(#\d+)-/g, '$1')
    })
}

const coversionTag = (str) => {
  return str.replace(/(\<span[^\<\/span\>]+\<\/span\>)/g, (spanHtml) => {
    const likeId = spanHtml.match(/(#\d+)/g)
    return (likeId ? likeId[0] : '') || ''
  })
}

export default {
  props: {
    pid: {
      type: Number,
      default: 0, //回复的话，取上一个帖子的ID，新增帖子的话传0
    },
    forumInfo: {
      type: Object,
      default: {},
    },
    getData: {
      type: Function,
    },
    type: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      innerText: '',
      loading: false,
      textarea: '',
      checkCate: '',
      tagList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      imgList: [],
    }
  },
  computed: {
    disabled() {
      if (this.textarea) {
        return false
      } else {
        return true
      }
    },
    i18n() {
      return this.$t('messages')
    },
  },
  mounted() {
    this.getCate()
  },
  methods: {
    handleInput: debounce(async function () {
      const editEl = this.$refs.edit
      const selection = window.getSelection()
      const startOffset = selection.getRangeAt(0).startOffset
      const textNode = selection.focusNode
      // 插入光标位置
      const iEl = document.createElement('i')
      iEl.setAttribute('name', 'editCursor')
      textNode.parentElement.insertBefore(iEl, textNode.nextSibling)

      const html = editEl.innerHTML
      editEl.innerHTML = await conversionId(html)
      this.textarea = editEl.innerHTML

      this.$nextTick(() => {
        try {
          const range = document.createRange()
          const cursorIEl = editEl.querySelector(
            '[name="editCursor"]'
          ).previousSibling
          range.setStart(
            cursorIEl,
            Math.min(cursorIEl.length || 0, startOffset)
          )
          range.collapse(true)
          window.getSelection().removeAllRanges()
          window.getSelection().addRange(range)
        } catch (e) {
          console.log(e)
        }

        document
          .querySelectorAll('[name="editCursor"]')
          .forEach((el) => el.parentElement.removeChild(el))
      })
    }, 500),
    handleRemove(file) {
      this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(
        (item, index) => {
          if (item.uid == file.uid) {
            this.imgList.splice(index, 1)
          }
          return item.uid != file.uid
        }
      )
    },
    uploadRequest(param) {
      upload.file({
        file: param.file,
        fileName: param.file.name,
        onSuccess: ({ data }) => {
          this.imgList.push(data.oss_url)
        },
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getCate() {
      api.getCate({}).then((res) => {
        this.tagList = res.data.map((item) => {
          item.id = item.id.toString()
          return item
        })
      })
    },
    submit() {
      const checkListNum = this.checkCate
        ? [+this.tagList.find((m) => m.name === this.checkCate).id]
        : []
      if (!this.textarea) {
        this.$layer.msg(
          "<div class='error-icon'></div><div>你还没有输入内容</div>"
        )
        return
      }
      this.loading = true
      api
        .forumAdd({
          pid: this.pid,
          name: this.textarea,
          cate_list: checkListNum.join(','), //分类的ID(1,2)逗号分隔
          tag_list: '', //标签的值(66,89)机经ID逗号分隔
          img_list: this.imgList.toString(), //图片逗号分隔
        })
        .then((res) => {
          if (res.code == 200) {
            this.loading = false
            this.$layer.msg(
              "<div class='success-icon-big'></div><div>已发布</div>"
            )
            this.$refs.edit.innerHTML = ''
            this.textarea = ''
            this.checkList = []
            this.$set(this.imgList)
            this.imgList = []
            this.$refs.upload.clearFiles()
            this.getData()
            this.$emit('clier', false)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.add {
  border: 1px solid #dcdcdc;
  border-radius: 10px;

  .edit {
    width: 100%;
    min-height: 100px;
    max-height: 200px;
    overflow: auto;
    padding: 17px;
    line-height: 38px;
    ::v-deep .practice {
      display: inline-block;
      background-color: #f4f4f4;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 30px;
      line-height: 30px;
      position: relative;
      text-decoration: none;
      color: #1f1f40;
      > i {
        position: absolute;
        right: 10px;
        font-size: 12px;
        color: #bfbfbf;
        cursor: pointer;
      }
      > b {
        font-weight: normal;
        color: #3b86ff;
      }
    }
  }
  ::v-deep .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    color: #fff;
    background-color: #bfbfbf;
    border-color: #bfbfbf;
  }
  ::v-deep .el-radio.is-bordered.el-radio--mini {
    margin-right: 18px;
  }
  ::v-deep .el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 0px;
  }
  ::v-deep .el-radio.is-bordered.is-checked {
    border-color: #3b86ff;
    background-color: #3b86ff;
  }
  ::v-deep .el-button--primary {
    color: #fff;
    background-color: #3b86ff;
    border-color: #3b86ff;
  }
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #fff;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner,
  .el-radio__input.is-indeterminate .el-radio__inner {
    background-color: #3b86ff;
    border-color: #3b86ff;
  }
  ::v-deep .el-radio__inner:hover {
    border-color: #dcdfe6;
  }
  ::v-deep .el-textarea__inner {
    border: none;
    border-radius: 10px;
    resize: none;
  }
  ::v-deep .el-textarea__inner:focus {
    border-color: #fff;
  }
  .addBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .addBox {
      display: flex;
      .bottomImg {
        margin-left: 18px;
        cursor: pointer;
      }
      .bottomImg2 {
        margin-left: 18px;
        width: 22px;
        height: 22px;
        background: url('../../../assets/ht.png');
        background-size: cover;
        cursor: pointer;
      }
      .bottomImg2:hover {
        background: url('../../../assets/ht2.png');
        cursor: pointer;
      }
    }
    .btn {
      width: 79px;
      height: 30px;
      padding: 0;
      margin-right: 18px;
    }
  }
}
</style>

<style lang="scss">
.forum-popver-popover {
  .el-upload-list__item-actions {
    width: 100% !important;
  }
  .el-upload-list--picture-card {
    width: 72px;
    height: 72px;
  }
  .el-upload--picture-card:hover,
  .el-upload:focus {
    border-color: #3b86ff;
    color: #3b86ff;
  }
  .el-upload--picture-card:hover,
  .el-upload:focus {
    border-color: #3b86ff;
    color: #3b86ff;
  }
  .el-upload--picture-card {
    width: 72px;
    height: 72px;
    line-height: 72px;
  }
}
</style>
