<template>
  <div class="forum-box">
    <div class="gre-stat">
      <h3>{{ i18n.Forum.data }}</h3>
      <div class="item">
        <div class="item-text">
          <div class="title">{{ i18n.Forum.data2 }}</div>
          <div class="num">{{ info.gre_week_add }}</div>
        </div>
        <v-chart
          class="gre-stat-chart"
          :options="getOptions(gre_week_add_map)"
        />
      </div>
      <div class="item">
        <div class="item-text">
          <div class="title">{{ i18n.Forum.data3 }}</div>
          <div class="num">{{ info.gre_gp_total }}</div>
        </div>
        <v-chart
          class="gre-stat-chart"
          :options="getOptions(gre_gp_total_map)"
        />
      </div>
      <div class="item">
        <div class="item-text">
          <div class="title">{{ i18n.Forum.data4 }}</div>
          <div class="num">
            {{ info.gre_total }}
          </div>
        </div>
        <v-chart class="gre-stat-chart" :options="getOptions(gre_total_map)" />
      </div>
      <div class="item">
        <div class="item-text">
          <div class="title">{{ i18n.Forum.data5 }}</div>
          <div class="num">{{ info.gre_hit_rate }}%</div>
        </div>
        <v-chart
          class="gre-stat-chart"
          :options="getOptions(gre_hit_rate_map)"
        />
      </div>
    </div>
    <img
      class="shequbanner"
      src="../../../assets/shequbanner.png"
      alt=""
      @click="handleFileDown"
    />
    <el-card class="box-card">
      <div class="title">{{ i18n.Forum.title }}</div>
      <img :src="helperInfo.site_val" alt="" />
      <div class="text">{{ helperInfo.remark }}</div>
    </el-card>
    <el-card class="box-card2">
      <div class="title">{{ i18n.Forum.title3 }}</div>
      <div class="forum-list-wrap" ref="listWrap">
        <ul class="forum-list" ref="list">
          <li
            class="forum-list-item"
            v-for="(item, index) of list"
            :key="item.id"
          >
            <div>
              <img class="forum-list-user" :src="item.tag_user.headimgurl" />
              <div class="isVip" v-if="item.tag_user.is_vip">
                <img :src="require('@/assets/vip-icon.png')" alt />
              </div>
            </div>
            <div class="content">
              <div class="header">
                <div class="userName">{{ item.tag_user.name }}</div>
                <div class="headerTitle">{{ item.tag_user.user_goal }}</div>
              </div>
              <div class="contentBox">
                <a
                  class="text"
                  :href="
                    `/#/Exercises?clid=${item.cate_id}&practiceId=${item.question_id}&from=search`
                  "
                  target="_blank"
                  >#{{ item.tag_id }} {{ item.tag_title }}</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-card>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import { mapState } from 'vuex'
import api from '@/api/forum'
import 'echarts/lib/chart/line'
import { apiGreHomeStat } from '@/api/api.js'
export default {
  components: {
    'v-chart': ECharts
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    }),
    i18n() {
      return this.$t('messages')
    }
  },
  data() {
    return {
      gre_total_map: [1, 2, 3, 4, 3, 5, 4, 5, 5, 7],
      gre_gp_total_map: [3, 5, 5, 5, 3, 4, 3, 5, 6, 8],
      gre_week_add_map: [3, 5, 3, 5, 5, 5, 3, 5, 5, 7],
      gre_hit_rate_map: [2, 3, 4, 5, 4, 5, 5, 5, 4, 7],
      info: {},
      list: []
    }
  },
  mounted() {
    this.fetchGreStat()
    this.getTag()
  },
  methods: {
    getOptions(list) {
      const mapList = list || []
      return {
        grid: {
          left: 0,
          right: 0
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            data: list,
            type: 'line',
            showSymbol: false,
            smooth: true,
            itemStyle: {
              normal: {
                color: 'rgba(24, 144, 255, 0.8)'
              }
            },
            areaStyle: {
              normal: {
                color: '#d4e9ff'
              }
            }
          }
        ]
      }
    },
    fetchGreStat() {
      apiGreHomeStat().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.info = res.data
      })
    },
    getTag() {
      api.forumTagFeed().then((res) => {
        if (res.code == 200) {
          this.list = res.data
          this.$nextTick(() => {
            this.startAutoScroll()
          })
        }
      })
    },
    startAutoScroll() {
      const wrapEl = this.$refs.listWrap
      const el = this.$refs.list
      let top = 0
      const maxTop = el.scrollHeight - wrapEl.clientHeight
      this.timeId = setInterval(() => {
        top += 3
        el.style.transform = `translateY(-${top}px)`
        if (top >= maxTop) {
          clearTimeout(this.timeId)

          this.getTag()
        }
      }, 200)
    },
    handleFileDown() {
      this.$router.push('FileDownload')
    }
  }
}
</script>

<style lang="scss" scoped>
.forum-box {
  display: flex;
  flex-direction: column;
  .shequbanner {
    width: 100%;
    margin-top: 19px;
    object-fit: cover;
    cursor: pointer;
  }
  .gre-stat {
    padding: 18px 21px;
    height: 368px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .gre-stat-chart {
      width: 160px;
      height: 40px;
    }
    h3 {
      margin-bottom: 20px;
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 26px;
      &:last-child {
        margin-bottom: 0;
      }
      .item-text {
        display: flex;
        .title {
          width: 74px;
        }
      }
    }
    .title {
      margin-bottom: 5px;
    }
    .num {
      font-size: 22px;
      span {
        font-size: 14px;
        color: #ff606d;
        i {
          font-size: 12px;
        }
      }
    }
  }
  .box-card {
    margin-top: 19px;
    border-radius: 10px;
    height: 285px;
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
      color: #1f1f40;
    }
    img {
      width: 130px;
      height: 130px;
      display: block;
      margin: 0 auto;
      margin-top: 19px;
    }
    .text {
      font-size: 16px;
      line-height: 19px;
      color: #1f1f40;
      text-align: center;
      margin-top: 16px;
    }
  }
  .box-card2 {
    border-radius: 10px;
    max-height: 626px;
    margin-top: 34px;
    .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      color: #1f1f40;
      margin-top: 12px;
      margin-left: 17px;
    }
    .forum-list-wrap {
      margin-top: 24px;
      max-height: 430px;
      overflow: hidden;
    }
    .forum-list {
      position: relative;
      transition: all 0.2s linear;
      .forum-list-item {
        margin-bottom: 40px;
        margin-left: 17px;
        position: relative;
        display: flex;
        .forum-list-user {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #eee;
          margin-right: 17px;
        }
        .isVip {
          position: absolute;
          left: 24px;
          top: 28px;
          > img {
            width: 17px;
            height: 17px;
          }
        }
        .content {
          .header {
            display: flex;
            align-items: flex-end;
            .userName {
              color: #7c8084;
              font-size: 15px;
            }
            .headerTitle {
              margin-left: 6px;
              font-size: 12px;
              color: #9c9c9c;
              opacity: 0.8;
            }
          }
          .contentBox {
            margin-top: 6px;
            .text {
              font-size: 16px;
              line-height: 19px;
              color: #3b86ff;
            }
            .imgs {
              width: 250px;
              img {
                margin-right: 11px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
