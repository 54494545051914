<template>
  <div class="gre-wrap">
    <div class="forum-header">
      <div v-loading="loading && page === 1">
        <div v-if="list.length">
          <ul class="forum-list">
            <li
              class="forum-list-item"
              v-for="(item, index) of list"
              :key="item.id"
            >
              <div>
                <img class="forum-list-user" :src="item.user_info.avatar" />
              </div>
              <div class="content">
                <div class="header">
                  <div class="userName">{{ item.user_info.name }}</div>
                  <div class="headerTitle">{{ item.user_info.user_goal }}</div>
                </div>
                <div class="contentBox">
                  <span class="text">{{ item.content }}</span>
                  <Audio :url="item.audio_url" v-if="item.audio_url" />
                </div>
                <div class="bottom">
                  <span class="time">{{ item.created_at }}</span>
                </div>
                <ul class="forum-list" v-if="item.is_reply == 1">
                  <li class="forum-list-item">
                    <div>
                      <img
                        class="forum-list-user"
                        :src="item.teacher_info.avatar"
                      />
                    </div>
                    <div class="content">
                      <div class="header">
                        <div class="userName">{{ item.teacher_info.name }}</div>
                        <div class="headerTitle">{{i18n.Forum.teacher}}</div>
                      </div>
                      <div class="contentBox">
                        <span class="text">{{ item.reply_content }}</span>
                        <Audio
                          :url="item.reply_audio[0].file_path"
                          v-if="item.reply_audio && item.reply_audio[0]"
                        />
                      </div>
                      <div class="bottom">
                        <span class="time">{{ item.replay_time }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <More
            :page="page"
            :pageSize="pageSize"
            :loading="loading"
            :totalPage="Math.ceil(total / pageSize)"
            @load="handleLoad"
          />
        </div>
      </div>
    </div>
    <Empty v-if="!list.length && !loading" isTabs />
  </div>
</template>

<script>
import api from '@/api/forum'
import Audio from '@/components/Audio/Audio.vue'
import Empty from '@/components/ExercisesEmpty.vue'
import More from '@/components/more'

export default {
  components: {
    Audio,
    Empty,
    More,
  },
  data() {
    return {
      list: [],

      page: 1,
      pageSize: 10,
      total: 0,

      loading: false,
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  created() {
    this.greList()
  },
  methods: {
    async greList() {
      const { data } = await api.greList({
        page: this.page,
        page_size: 10,
      })

      this.list = this.page === 1 ? data.list : this.list.concat(data.list)
      this.total = data.total
    },
    handleLoad(page) {
      this.page = page
      this.greList()
    },
  },
}
</script>

<style scoped lang="scss">
.gre-wrap {
  ::v-deep .el-tag.el-tag--success {
    background-color: #c9e2ff;
    border-color: #c9e2ff;
    color: #3b86ff;
  }

  ::v-deep .audio-content {
    margin-top: 4px;
  }

  .forum-header {
    .forum-list {
      .forum-list-item {
        margin-top: 17px;
        position: relative;
        border-top: 1px solid #dcdcdc;
        padding-top: 25px;
        display: flex;

        .forum-list-user {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #eee;
          margin-right: 12px;
        }

        .isVip {
          position: absolute;
          left: 30px;
          top: 60px;

          > img {
            width: 17px;
            height: 17px;
          }
        }

        .content {
          width: 100%;

          .header {
            display: flex;
            align-items: flex-end;

            .userName {
              color: #7c8084;
              font-size: 15px;
            }

            .headerTitle {
              margin-left: 6px;
              font-size: 12px;
              color: #9c9c9c;
              opacity: 0.8;
            }
          }

          .contentBox {
            .text {
              margin-top: 6px;
              font-size: 16px;
              line-height: 38px;
              color: #1f1f40;

              ::v-deep .practice {
                display: inline-block;
                background-color: #f4f4f4;
                border-radius: 5px;
                padding-left: 10px;
                padding-right: 10px;
                line-height: 30px;
                position: relative;

                > i {
                  position: absolute;
                  right: 10px;
                  font-size: 12px;
                  color: #bfbfbf;
                  cursor: pointer;
                  display: none;
                }

                > b {
                  font-weight: normal;
                  color: #3b86ff;
                }
              }
            }

            .imgs {
              width: 250px;
              margin-top: 9px;

              img {
                margin-right: 11px;
              }
            }
          }

          .bottom {
            display: flex;
            align-items: flex-end;
            margin-top: 13px;
            font-size: 12px;
            font-weight: 400;
            color: #9c9c9c;
            opacity: 0.8;

            .time {
              margin-right: 18px;
            }

            .favour {
              margin-right: 18px;
              cursor: pointer;
            }

            .reply {
              margin-right: 18px;
              cursor: pointer;
            }

            .del-btn {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
