import { post, get, getToken, postFormData } from './request'

export default {
  getCate: (p) => get('/api-web/forum/cate', p),
  getList: (p) => post('/api-web/forum/list', p),
  forumAdd: (p) => post('/api-web/forum/add', p),
  forumLikeAdd: (p) => post('/api-web/forum/like-add', p),
  forumLikeDel: (p) => post('/api-web/forum/like-del', p),
  forumDel: (p) => post('/api-web/forum/del', p),
  forumInfo: (p) => post('/api-web/forum/info', p),
  forumTagFeed: (p) => post('/api-web/forum/tag-feed', p),
  greList: (p) => get('/api-web/forum/user-ask-list', p)
}
