<template>
  <div class="compont-img">
    <img
      class="img"
      :width="width"
      :height="height"
      :src="src"
      alt=""
      @click="showBigImg = true"
    />

    <el-dialog
      :visible.sync="showBigImg"
      :show-close="false"
      custom-class="img-com-big-img-dialog"
    >
      <div class="small-img-wrap">
        <img :src="src" alt class="small-img" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    }
  },
  data() {
    return {
      showBigImg: false
    }
  }
}
</script>

<style scoped lang="scss">
.compont-img {
  margin: 0 6px;
  .img {
    object-fit: cover;
  }
}
</style>

<style lang="scss">
.img-com-big-img-dialog {
  background: transparent;
  box-shadow: none;
  .small-img-wrap {
    text-align: center;
    .small-img {
      cursor: url('~@/assets/small-img.png'), auto;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
