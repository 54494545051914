<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="60%">
      <div class="hfText" slot="title">回复</div>
      <ul class="forum-list">
        <li class="forum-list-item">
          <div>
            <img
              class="forum-list-user"
              :src="forumInfo.user_info && forumInfo.user_info.headimgurl"
            />
            <div
              class="isVip"
              v-if="forumInfo.user_info && forumInfo.user_info.is_vip"
            >
              <img :src="require('@/assets/vip-icon.png')" alt />
            </div>
          </div>
          <div class="content">
            <div class="header">
              <div class="userName">
                {{ forumInfo.user_info && forumInfo.user_info.name }}
              </div>
              <div class="headerTitle">
                {{ forumInfo.user_info && forumInfo.user_info.user_goal }}
              </div>
              <el-tag
                style="margin-left: 16px"
                size="mini"
                :type="
                  v == '考场回忆'
                    ? 'warning'
                    : v == '经验分享'
                    ? 'success'
                    : 'danger'
                "
                v-for="(v, i) in getTag(forumInfo.cate_list)"
                :key="i"
              >
                {{ v }}
              </el-tag>
            </div>
            <div class="contentBox">
              <div class="text" v-html="forumInfo.name"></div>
              <div class="imgs" v-if="forumInfo.img_list">
                <img
                  v-for="(img, idx) in forumInfo.img_list.split(',')"
                  :key="idx"
                  width="72"
                  :src="img"
                  alt=""
                />
              </div>
            </div>
            <div class="bottom">
              <span class="time">{{ forumInfo.created_at }}</span>
              <span class="favour"
                ><i
                  class="f-icon"
                  @click="handleLike(forumInfo.id, forumInfo.is_liked, index)"
                  :class="[forumInfo.is_liked ? 'icon-likes' : 'icon-like']"
                />&nbsp;{{ forumInfo.like_cnt }}</span
              >
              <span class="reply" @click="reply(forumInfo.id)">回复</span>
            </div>
          </div>
        </li>
      </ul>
      <ADD
        class="add"
        :pid="forumInfo.id"
        @clier="clier"
        :getData="getData"
        :forumInfo="forumInfo"
        type="reply"
      />
    </el-dialog>
  </div>
</template>

<script>
import ADD from './add.vue'
import api from '@/api/forum'
export default {
  components: {
    ADD
  },
  props: {
    tabList: {
      type: Array
    },
    getData: {
      type: Function
    }
  },
  data() {
    return {
      dialogVisible: false,
      forumInfo: {}
    }
  },
  methods: {
    open() {
      this.dialogVisible = true
    },
    reply() {},
    clier(v) {
      this.dialogVisible = v
    },
    getTag(val) {
      if (val) {
        const tagArr = []
        this.tabList.map((item) => {
          val.split(',').map((v) => {
            if (item.id == v) {
              tagArr.push(item.name)
            }
          })
        })
        return tagArr
      }
    },
    getInfo(id) {
      api.forumInfo({ forum_id: id }).then((res) => {
        if (res.code == 200) {
          this.forumInfo = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-tag.el-tag--success {
  background-color: #c9e2ff;
  border-color: #c9e2ff;
  color: #3b86ff;
}
.hfText {
  font-size: 14px;
  color: #bfbfbf;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 6px;
  text-align: left;
}
.add {
  margin-top: 23px;
}
.forum-list {
  .forum-list-item {
    position: relative;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    .forum-list-user {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #eee;
      margin-right: 12px;
    }
    .isVip {
      position: absolute;
      left: 24px;
      top: 28px;
      > img {
        width: 17px;
        height: 17px;
      }
    }
    .content {
      .header {
        display: flex;
        align-items: flex-end;
        .userName {
          color: #7c8084;
          font-size: 15px;
        }
        .headerTitle {
          margin-left: 6px;
          font-size: 12px;
          color: #9c9c9c;
          opacity: 0.8;
        }
      }
      .contentBox {
        ::v-deep .practice {
          display: inline-block;
          background-color: #f4f4f4;
          border-radius: 5px;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 30px;
          position: relative;
          text-decoration: none;
          color: #1f1f40;
          > i {
            position: absolute;
            right: 10px;
            font-size: 12px;
            color: #bfbfbf;
            cursor: pointer;
            display: none;
          }

          > b {
            font-weight: normal;
            color: #3b86ff;
          }
        }
        .text {
          margin-top: 6px;
          font-size: 16px;
          line-height: 19px;
          color: #1f1f40;
        }
        .imgs {
          width: 250px;
          margin-top: 9px;
          img {
            margin-right: 11px;
          }
        }
      }
      .bottom {
        display: flex;
        align-items: flex-end;
        margin-top: 13px;
        margin-bottom: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #9c9c9c;
        opacity: 0.8;
        .time {
          margin-right: 18px;
        }
        .favour {
          margin-right: 18px;
          cursor: pointer;
        }
        .reply {
          margin-right: 18px;
          cursor: pointer;
        }
        .del-btn {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
