<template>
  <div class="forum-wrap">
    <div class="forum-header">
      <div v-loading="loading && page === 1">
        <div v-if="list.length">
          <ul class="forum-list">
            <li
              class="forum-list-item"
              v-for="(item, index) of list"
              :key="item.id"
            >
              <div>
                <img class="forum-list-user" :src="item.user_info.headimgurl" />
                <div class="isVip" v-if="item.user_info.is_vip">
                  <img :src="require('@/assets/vip-icon.png')" alt />
                </div>
              </div>
              <div class="content">
                <div class="header">
                  <div class="userName">{{ item.user_info.name }}</div>
                  <div class="headerTitle">{{ item.user_info.user_goal }}</div>
                  <el-tag
                    style="margin-left: 16px"
                    size="mini"
                    :type="
                      v == '考场回忆'
                        ? 'warning'
                        : v == '经验分享'
                        ? 'success'
                        : 'danger'
                    "
                    v-for="(v, i) in getTag(item.cate_list)"
                    :key="i"
                  >
                    {{ v }}
                  </el-tag>
                </div>
                <div class="contentBox">
                  <div class="text" v-html="item.name"></div>
                  <div class="imgs" v-if="item.img_list">
                    <Img
                      v-for="(img, idx) in item.img_list.split(',')"
                      :key="idx"
                      :width="72"
                      :height="72"
                      :src="img"
                      alt=""
                    />
                  </div>
                </div>
                <div class="bottom">
                  <span class="time">{{ item.created_at }}</span>
                  <span class="favour"
                    ><i
                      class="f-icon"
                      @click="handleLike(item.id, item.is_liked, index)"
                      :class="[item.is_liked ? 'icon-likes' : 'icon-like']"
                    />&nbsp;{{ item.like_cnt }}</span
                  >
                  <span class="reply" @click="reply(item.id)">{{
                    i18n.Forum.reply
                  }}</span>
                  <span
                    v-if="item.user_id == userInfo.id"
                    class="f-icon icon-del del-btn"
                    @click="delReply(item.id)"
                  />
                </div>
                <ul class="forum-list">
                  <li
                    class="forum-list-item"
                    v-for="(val, idx) of item.showMore
                      ? item.reply_list
                      : item.reply_list.slice(0, 1)"
                    :key="val.id"
                  >
                    <div>
                      <img
                        class="forum-list-user"
                        :src="val.user_info.headimgurl"
                      />
                      <div class="isVip" v-if="val.user_info.is_vip">
                        <img :src="require('@/assets/vip-icon.png')" alt />
                      </div>
                    </div>
                    <div class="content">
                      <div class="header">
                        <div class="userName">{{ val.user_info.name }}</div>
                        <div class="headerTitle">
                          {{ val.user_info.user_goal }}
                        </div>
                        <el-tag
                          style="margin-left: 16px"
                          size="mini"
                          :type="
                            v == '考场回忆'
                              ? 'warning'
                              : v == '经验分享'
                              ? 'success'
                              : 'danger'
                          "
                          v-for="(v, i) in getTag(val.cate_list)"
                          :key="i"
                        >
                          {{ v }}
                        </el-tag>
                      </div>
                      <div class="contentBox">
                        <div class="text" v-html="val.name"></div>
                        <div class="imgs" v-if="val.img_list">
                          <Img
                            v-for="(img, idx) in val.img_list.split(',')"
                            :key="idx"
                            :width="72"
                            :height="72"
                            :src="img"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="bottom">
                        <span class="time">{{ val.created_at }}</span>
                        <span class="favour"
                          ><i
                            class="f-icon"
                            @click="
                              handleLike(val.id, val.is_liked, idx, index)
                            "
                            :class="[val.is_liked ? 'icon-likes' : 'icon-like']"
                          />&nbsp;{{ val.like_cnt }}</span
                        >
                        <span class="reply" @click="reply(val.id)">{{i18n.Forum.reply}}</span>
                        <span
                          v-if="val.user_id == userInfo.id"
                          class="f-icon icon-del del-btn"
                          @click="delReply(val.id)"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  class="reply-total"
                  v-if="item.reply_list.length > 1 && !item.showMore"
                >
                  {{i18n.Forum.common}}{{ item.reply_list.length }}{{i18n.Forum.reply}}，<el-button
                    type="text"
                    class="reply-view"
                    @click="showMoreComments(index)"
                    >{{i18n.Forum.Click}}</el-button
                  >
                </div>
              </div>
            </li>
          </ul>
          <More
            :page="page"
            :pageSize="pageSize"
            :loading="loading"
            :totalPage="Math.ceil(total / pageSize)"
            @load="load"
          />
        </div>
      </div>
    </div>
    <Reply ref="reply" :tabList="tabList" :getData="getData" />
    <Empty v-if="!list.length && !loading" isTabs />
  </div>
</template>

<script>
import Reply from './reply.vue'
import { mapState } from 'vuex'
import Empty from '@/components/ExercisesEmpty.vue'
import More from '@/components/more'
import api from '@/api/forum'
import _ from 'lodash'
import Img from '@/components/img'
import i18n from '@/i18n/i18n'

export default {
  components: {
    Empty,
    More,
    Reply,
    Img,
  },
  props: {
    cate: {
      type: Number,
    },
    tabList: {
      type: Array,
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      list: [],
      id: null,
      loading: false,
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
    }),
    i18n() {
      return this.$t('messages')
    },
  },
  watch: {
    cate() {
      this.page = 1
      this.getData()
    },
  },
  mounted() {
    this.getData(1)
  },
  methods: {
    load(page) {
      this.getData(page)
    },
    showMoreComments(index) {
      this.list[index].showMore = true
    },
    handleLike(id, is_liked, index, itemIndex) {
      if (is_liked) {
        api.forumLikeDel({ forum_id: id }).then((res) => {
          if (res.code == 200) {
            this.$layer.msg(res.msg)
            if (itemIndex >= 0) {
              this.list[itemIndex].reply_list[index].like_cnt =
                this.list[index].reply_list[itemIndex].like_cnt > 0
                  ? this.list[index].reply_list[itemIndex].like_cnt - 1
                  : 0
              this.list[itemIndex].reply_list[index].is_liked = 0
            } else {
              this.list[index].like_cnt = this.list[index].like_cnt - 1
              this.list[index].is_liked = 0
            }
          }
        })
      } else {
        api.forumLikeAdd({ forum_id: id }).then((res) => {
          if (res.code == 200) {
            this.$layer.msg(res.msg)
            if (itemIndex >= 0) {
              this.list[itemIndex].reply_list[index].like_cnt =
                this.list[index].reply_list[itemIndex].like_cnt + 1
              this.list[itemIndex].reply_list[index].is_liked = 1
            } else {
              this.list[index].like_cnt = this.list[index].like_cnt + 1
              this.list[index].is_liked = 1
            }
          }
        })
      }
    },
    reply(id) {
      this.$refs.reply.getInfo(id)
      this.$refs.reply.open()
    },
    delReply(id) {
      this.$confirm(
        i18n.locale === 'ch' ? '确认删除本贴' : 'Confirm to delete this post',
        i18n.locale === 'ch' ? '删除' : 'delete',
        {
          confirmButtonText: i18n.locale === 'ch' ? '确定' : 'determine',
          cancelButtonText: i18n.locale === 'ch' ? '取消' : 'cancel',
          type: 'warning',
        }
      ).then(() => {
        api.forumDel({ forum_id: id }).then((res) => {
          if (res.code == 200) {
            this.$layer.msg(
              "<div class='success-icon-big'></div><div>已删除</div>"
            )
            this.getData()
          }
        })
      })
    },
    getData(page = 1) {
      if (page) {
        this.page = page
      }
      api
        .getList({
          page: this.page,
          page_size: this.pageSize,
          cate: this.cate == 4 ? 0 : this.cate, //进行分类查询
          is_my: this.cate == 0 ? 1 : 0, //是否是我的
        })
        .then((res) => {
          if (res.code == 200) {
            this.total = res.data.pager.total_cnt
            this.page = res.data.pager.page
            const list = res.data.list.map((item, index) => {
              if (item.reply_list.length > 1) {
                item.showMore = false
              }
              return item
            })
            this.list = this.page === 1 ? list : [...this.list, ...list]
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getTag(val) {
      const tagArr = []
      this.tabList.map((item) => {
        val.split(',').map((v) => {
          if (item.id == v) {
            tagArr.push(item.name)
          }
        })
      })
      return tagArr
    },
  },
}
</script>

<style lang="less" scoped>
.forum-wrap {
  .reply-total {
    color: #7c8084;
    font-size: 14px;

    .reply-view {
      color: #3b86ff;
    }
  }

  ::v-deep .el-tag.el-tag--success {
    background-color: #c9e2ff;
    border-color: #c9e2ff;
    color: #3b86ff;
  }

  .forum-header {
    .forum-list {
      .forum-list-item {
        margin-top: 17px;
        position: relative;
        border-top: 1px solid #dcdcdc;
        padding-top: 25px;
        display: flex;

        .forum-list-user {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #eee;
          margin-right: 12px;
        }

        .isVip {
          position: absolute;
          left: 30px;
          top: 60px;

          > img {
            width: 17px;
            height: 17px;
          }
        }

        .content {
          width: 100%;

          .header {
            display: flex;
            align-items: flex-end;

            .userName {
              color: #7c8084;
              font-size: 15px;
            }

            .headerTitle {
              margin-left: 6px;
              font-size: 12px;
              color: #9c9c9c;
              opacity: 0.8;
            }
          }

          .contentBox {
            .text {
              margin-top: 6px;
              font-size: 16px;
              line-height: 19px;
              color: #1f1f40;
              line-height: 38px;

              ::v-deep .practice {
                display: inline-block;
                background-color: #f4f4f4;
                border-radius: 5px;
                padding-left: 10px;
                padding-right: 10px;
                line-height: 30px;
                position: relative;
                text-decoration: none;
                color: #1f1f40;
                > i {
                  position: absolute;
                  right: 10px;
                  font-size: 12px;
                  color: #bfbfbf;
                  cursor: pointer;
                  display: none;
                }

                > b {
                  font-weight: normal;
                  color: #3b86ff;
                }
              }
            }

            .imgs {
              width: 250px;
              margin-top: 9px;
              display: flex;
              img {
                margin-right: 11px;
              }
            }
          }

          .bottom {
            display: flex;
            align-items: flex-end;
            margin-top: 13px;
            font-size: 12px;
            font-weight: 400;
            color: #9c9c9c;
            opacity: 0.8;

            .time {
              margin-right: 18px;
            }

            .favour {
              margin-right: 18px;
              cursor: pointer;
            }

            .reply {
              margin-right: 18px;
              cursor: pointer;
            }

            .del-btn {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
