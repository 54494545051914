<template>
  <div class="forum-stream">
    <el-card>
      <div slot="header">
        <el-tabs v-model="activeName">
          <el-tab-pane
            v-for="(item, index) in tabList"
            :key="index"
            :name="item.id"
          >
            <div slot="label" class="tab-name">
              <span>{{ item.name }}</span>
              <img v-if="item.img" :src="item.img" alt="" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="content">
        <ADD :getData="handleRefresh" />
      </div>
      <Gre v-if="activeName === '10'" />
      <Forum v-else :cate="activeName" ref="Forum" :tabList="tabList" />
    </el-card>
  </div>
</template>

<script>
import Forum from './forum'
import Gre from './gre'
import api from '@/api/forum'
import ADD from './add.vue'
import recommendImg from '@/assets/recommend.png'
import i18n from '@/i18n/i18n'

export default {
  components: {
    ADD,
    Forum,
    Gre
  },
  data() {
    return {
      activeName: i18n.locale == 'ch' ? '10' : '4',
      tabList: []
    }
  },
  created() {
    this.getCate()
  },
  methods: {
    getCate() {
      api.getCate({}).then((res) => {
        this.tabList = [
          { id: '4', name: i18n.locale === 'ch' ? '全部' : 'All' }
        ]
          .concat(
            this.$i18n.locale == 'ch'
              ? [
                  {
                    id: '10',
                    name:
                      i18n.locale === 'ch'
                        ? '老师答疑'
                        : 'Teacher answers questions',
                    img: recommendImg
                  }
                ]
              : []
          )
          .concat(
            res.data.map((item) => {
              if (item.name == '经验分享')
                item.name =
                  i18n.locale === 'ch' ? '经验分享' : 'Experience sharing'
              if (item.name == '考场回忆')
                item.name =
                  i18n.locale === 'ch'
                    ? '考场回忆'
                    : 'Examination room memories'
              item.id = item.id.toString()
              return item
            })
          )
          .concat([{ id: '0', name: i18n.locale === 'ch' ? '我的' : 'my' }])
      })
    },
    handleRefresh() {
      if (this.$refs.Forum) {
        this.$refs.Forum.getData()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.forum-stream {
  /deep/.el-card__body {
    padding: 0 20px 20px;
  }
  /deep/.el-card__header {
    padding: 18px 0;
    border-bottom: none;
  }
  /deep/.el-tabs__nav-wrap::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e4e7ed;
    z-index: 1;
  }
  /deep/.el-tabs__nav-wrap {
    padding: 0 30px;
  }

  /deep/ .el-tabs__item {
    height: 50px;
    &.is-active {
      .tab-name {
        color: #ef575b;
      }
    }
  }

  .tab-name {
    font-size: 20px;
    color: #1f1f40;
    display: flex;
    font-weight: normal;
    align-items: center;
    > img {
      margin-left: 3px;
    }
  }
}
</style>
